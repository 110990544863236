import * as React from "react"
import GradientJumbo from "../../components/gradient-jumbo"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import Founders from "../../components/sections/sobre-a-camarades/founders"
import LimeGradContact from "../../components/sections/sobre-a-camarades/lime_grad_contact"
import Objectives from "../../components/sections/sobre-a-camarades/objectives"
import OtherCamarades from "../../components/sections/sobre-a-camarades/other_camarades"
import Sponsors from "../../components/sections/sobre-a-camarades/sponsors"
import TeamBanner from "../../components/sections/sobre-a-camarades/team_banner"
import Seo from "../../components/seo"

const AboutPage = props => (
  <Layout language="en" pathname={props.location.pathname}>
    <Seo
      title="About CAMARADES Brazil"
      lang="en"
      description="Learn more about us"
    />
    <GradientJumbo
      subtitle="About CAMARADES Brazil"
      title="A collaborative community"
    />
    <div className="container">
      <div className="camarades-layout-globe"></div>
      <Objectives language="en" />
      <Founders language="en" />
      <TeamBanner language="en" />
      <Sponsors language="en" />
      <LimeGradContact language="en" />
      <OtherCamarades language="en" />
    </div>
    <Contact language="en" />
  </Layout>
)

export default AboutPage
